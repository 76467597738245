import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import router from "../../user-router";

const state = {
  showModalQrCode: false
}
const mutations = {
  SET_SHOW_MODAL_QR_CODE(state, payload){
    state.showModalQrCode = payload
  }
}
const actions = {
  async updateLastLogin({ state, commit, dispatch }, payload){
    const res = await ApiCaller(null, 'user').post(URLS.UPDATE_LAST_LOGIN(),{
      customer: payload
    })
  },
  async checkCustomerScanQrcode({ state, commit }, payload){

    if (payload === 'https://wellness-app.parco.jp/user/qrcode/store') {
      router.push({name: 'QrStoreId'})
    } else {
      const res = await ApiCaller().get(URLS.CHECK_CUSTOMER_SCAN_QR_CODE(),{
        params: {qr_id: payload, check_in_out: localStorage.getItem('checkInCheckOut')}
      })
      if (res.data.status) {
        router.push({name: 'QrStoreId'})
      } else {
        commit('SET_SHOW_MODAL_QR_CODE', true)
        return false
      }
    }
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}
